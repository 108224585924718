<template>
  <div id="intro" class="[ bg-n-yellow ][ flex items-center justify-center ][ relative overflow-hidden ]">

    <transition name="fadeInZoom" appear>
      <div id="intro-1" class="intro-panel [ bg-white border border-black drop-shadow-lg ][ w-11/12 max-w-2xl mx-auto ][ p-6 md:p-12 lg:p-20 ][ text-center ]" :aria-hidden="currentIntro === 1 ? false : true">
        <template v-if="siteData">
          <h1 class="[ font-serif ][ text-4xl md:text-5xl lg:text-6xl ][ mb-6 ]">{{ siteData.intro_title ? siteData.intro_title : 'Welcome to Landing' }}</h1>
          <p v-if="siteData.intro_description" class="[ mb-8 ]">{{ siteData.intro_description }}</p>
          <button class="group [ bg-black text-white ][ inline-flex items-center ][ py-2 px-6 ][ relative overflow-hidden ]" @click="goToSecondIntro()">
            <span class="[ mr-2 z-10 ]">{{ siteData.intro_button_label ? siteData.intro_button_label : 'Enter Landing' }}</span>
            <nag-icon icon-name="arrow-forward"/>
          </button>
        </template>
      </div>
    </transition>

    <div v-if="siteData" id="intro-2" class="intro-panel [ w-11/12 max-w-4xl mx-auto ][ text-center ]" :class="currentIntro === 3 ? 'outro' : ''" :aria-hidden="currentIntro === 2 ? false : true">
      <blockquote class="[ font-serif ][ text-xl md:text-2xl lg:text-3xl ][ mb-8 ]" v-if="siteData.intro_quote">
        <p class="[ text-3xl md:text-4xl lg:text-5xl lg:leading-[3.5rem] ][ mb-6 ]">&ldquo;{{ siteData.intro_quote }}&rdquo;</p>
        <figcaption v-if="siteData.intro_quote_attribute">{{ siteData.intro_quote_attribute }}</figcaption>
      </blockquote>
      <button class="group [ bg-black text-white ][ inline-flex items-center ][ py-2 px-6 ][ relative overflow-hidden ]" @click="goToHome()">
        <span class="[ mr-2 z-10 ]">{{ siteData.intro_button_label_2 ? siteData.intro_button_label_2 : 'Begin' }}</span>
        <nag-icon icon-name="arrow-forward"/>
      </button>
    </div>

  </div>
</template>


<script>

  export default {
    data() {
      return {
        currentIntro: 1
      }
    },
    computed: {
      siteData () {
        return this.$store.state.site_data
      },
    },
    methods: {
      goToSecondIntro() {
        return this.currentIntro = 2
      },
      goToHome() {
        // update firstTime store
        this.$store.commit('set_firstTime', false)
        // go to home page
        let _this = this
        setTimeout( function(){
          _this.$router.push({name: 'Home'});
        }, 500)

        return this.currentIntro = 3
      },
    }
  }
</script>



<style scoped>

  .fadeInZoom-enter {
    opacity: 0;
    transform: scale(0);
  }

  .fadeInZoom-enter-active {
    animation: zoom-in 1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
  }


  @keyframes zoom-in {
    from {
      opacity: 0;
      transform: scale(.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }


  #intro {
    background-image: url('../assets/contour-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-blend-mode: multiply;
    min-height: calc(100% - 5rem);
  }

  .intro-panel {
    transition: 1.25s cubic-bezier(0.22, 1, 0.36, 1);
  }

  .intro-panel[aria-hidden="true"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  /* #intro-1 {
    opacity: 0;
    transform: scale(0);
  }
  #intro-1.loaded {
    opacity: 1;
    transform: scale(1);
    transition: .6s ease-out;
  } */
  #intro-2.outro {
    opacity: 0;
    transform: scale(0.5);
    transition: 1s cubic-bezier(0.5, 0, 0.75, 0);
  }


</style>